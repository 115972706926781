import React from 'react';

const Navbar = () => {
  return (
    <nav>
      <div className="nav-wrapper blue-grey lighten-5">
        <div className="brand-logo center blue-grey-text">EatMood</div>
      </div>
    </nav>
  );
}

export default Navbar;